import React, { createRef } from 'react'
import ListItem from './mini/ListItem'
import {ReactComponent as DashboardIcon} from '../assets/icons/dashboard.svg'
import {ReactComponent as SearchIcon} from '../assets/icons/search.svg'
import {ReactComponent as KeyIcon} from '../assets/icons/key.svg'
import {ReactComponent as PackageIcon} from '../assets/icons/package.svg'
import {ReactComponent as TransactionIcon} from '../assets/icons/creditlimit.svg'
import {ReactComponent as InsuranceIcon} from '../assets/icons/insurance.svg'
import { AccountBalanceWalletOutlined, FlightOutlined, 
  GroupsOutlined, LogoutOutlined, PeopleOutlined, PersonOutline, Settings } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toggleSidebar } from '../redux/reducers/sidebar/sidebarSlice'
import { logout } from '../redux/reducers/user/userSlice'
import { def } from '../config'


export default function Sidebar() {
  const dispatch = useDispatch();
  const sidebarRef = createRef();
  const {user} = useSelector(state => state.user.userData);

  
  function handleScroll(ev) {
    if(ev.deltaY > 0)
      sidebarRef.current.scrollTop += 30;
    else 
      sidebarRef.current.scrollTop -= 30;
    
  }
  return (
    <div ref={sidebarRef} className='sticky top-0 h-screen overflow-x-visible overflow-y-hidden px-2 bg-primary text-secondary' onWheel={handleScroll}>
      <div className='py-4 px-2 sticky top-0 bg-primary shadow-md z-10'>
        <h2 onClick={() => dispatch(toggleSidebar())} className='cursor-pointer '>
          {def.logo ? <img src={def.logo} alt='logo' className='w-[40px] scale-150' /> : def.siteName}
        </h2>
      </div>
      <div>
        <ListItem path='/' icon={<DashboardIcon className='' />} to="/" className="p-3" label="Dashboard" />
        {/* <ListItem path='/search' icon={<SearchIcon className='scale-125' />} to="/search" className="p-3" label="Search" /> */}
        <ListItem path='/flights' icon={<FlightOutlined className='rotate-90' />} className="p-3" label="Flights">
          <ListItem label='Order' to="/flights/order#Flight" />
          <ListItem label='Bookings' to="/flights" />
          <ListItem label='Ticket' to="/flights/ticket" />
          {/* <Link to="#">Queue</Link> */}
          <Link to="/flights/ImportPNR">Import PNR</Link>
          {/* <Link to="/flights/addons">Add-ons</Link> */}
          <Link to="/flights/advisory">Advisory</Link>
        </ListItem>
        <ListItem path='/hotels' icon={<KeyIcon />} className="p-3" label="Hotel">
          <ListItem to="/hotels/order#Hotel" label='Order' />
          <ListItem to="/hotels" label='My Booking' />
          {/* <Link to="#">Sub Agents</Link> */}
          {/* <Link to="#">Corporate</Link> */}
        </ListItem>
        <ListItem path='/packages' icon={<PackageIcon className='scale-125' />} className="p-3" label="Package">
          <ListItem to="/packages/order#Tour" label='Order' />
          <ListItem to="/packages" label='View Bookings' />
          {/* <Link to="/packages/view">View Packages</Link> */}
          <Link to="#">Review</Link>
        </ListItem>
        {/* <ListItem path='/insurance' to="/insurance" icon={<InsuranceIcon className='' />} className="p-3" label="Insurance" /> */}
        <ListItem path='/transactions' to="/transactions" icon={<TransactionIcon className='scale-125' />} className="p-3" label="Transaction">
          {/* <Link to="/transactions/all">All</Link>
          <Link to="/transactions/customer">Customers</Link>
          <Link to="/transactions/sub_agent">Agents</Link>
          <Link to="/transactions/corporate">Corporates</Link> */}
        </ListItem>
        <ListItem path="/credit" to="/credit" icon={<AccountBalanceWalletOutlined className='' />} className="p-3" label="Wallet" />
        <ListItem path="/customers" to="/customers" icon={<PersonOutline className='' />} className="p-3" label="Passengers" />
        {/* <ListItem path="/subagents" to="/subagents" icon={<PeopleOutlined className='' />} className="p-3" label="Sub Agents" /> */}
        {/* <ListItem path="/staff" to="/staff" icon={<GroupsOutlined className='' />} className="p-3" label="Staff" /> */}
        <ListItem icon={<Settings className='' />} className="p-3" label="Setting">
          <Link to={"/subagents/detail/"+user.id}>Profile</Link>  
          {/* <Link to="/settings/markups">Markups</Link>   */}
          {/* <Link to="#">Airline Deals</Link>   */}
          {/* <Link to="#">Suppliers</Link>   */}
          {/* <Link to="#">Content Manager</Link>   */}
          {/* <Link to="/settings/promotion">Promotion Content</Link>   */}
          {/* <Link to="#">Currency</Link>   */}
        </ListItem>
        <ListItem icon={<LogoutOutlined className='' />} className="p-3" label="Logout" onClick={() => dispatch(logout())} />
      </div>
    </div>
  )
}
