import { ArticleOutlined } from '@mui/icons-material';
import { Box, MenuItem, Tab, Tabs, TextField } from '@mui/material';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import SearchInput from '../../../components/mini/SearchInput';
import Pagination from '../../../components/mini/Pagination';
import getFlightOrders from '../../../controllers/flight/getFlightOrders';
import template, { templateImport } from '../../../features/flights/tableTemplate';
import { Avatar } from "@mui/material";
import DotMenu from '../../../components/mini/DotMenu';
import { Link } from 'react-router-dom';
import { CancelPNRModal } from '../../../components/flight/CancelPNRModal';
import { setModal, setModalComp } from '../../../redux/reducers/modal/modalSlice';
import { useDispatch} from 'react-redux';
import { IssueTicketModal } from '../../../components/flight/IssueTicketModal';
import FlightPayment from '../../../components/payment/FlightPayment';
import { getPromoCode } from '../../../controllers/settings/promo/getPromoCode';
import importPNRReq from '../../../controllers/flight/importPNR';
import { setAlertMsg } from '../../../redux/reducers/modal/snackBarSlice';
import getImportedPNRs from '../../../controllers/flight/getImportedPNRs';
import moment from 'moment';
import { formatMoney } from '../../../features/utils/formatMoney';


const rows = [
  // {
  //   id: 1, customer: '',bookingDate: null,status: ''
  // }
  // {
  //   id: 1, customer: 'Yeabsira Abebe',bookingDate: {date: '24th Oct , 2023',time: '8:29AM'},tripDetails: {airline:"Emirates",path:"LOS-DXB"},tripDate: {date: '24th Oct , 2023',time: '8:29AM'},
  //   PNR: 'GB234', amount: 123123,status: 'cancelled'
  // }
];
// [...Array(10-rows.length)].map((o,i) => rows.push({...rows[0],id:i+3}))



const filterItems = [
  {items:[{id: 0,field: 'status',operator: '',value:''}]},
  {items:[{id: 1,field: 'status', operator: 'equals', operatorValue: 'is', value: 'Ticketed'}]},
  {items:[{id: 2,field: 'status', operatorValue: 'is', value: 'PAID_FOR'}]},
  {items:[{id: 3,field: 'status', operatorValue: 'is', value: 'cancelled'}]},
  {items:[{id: 4,field: 'status', operatorValue: 'is', value: 'refund'}]},
];

function StatusCol({params,load}) {
  const [anchorEl, setAnchorEl] = useState();
  const dispatch = useDispatch();

  function issueTicket() {
    setAnchorEl(null);
    console.log('anchor setting null',anchorEl)
    dispatch(setModal(true))
    let id = params.id;
    dispatch(setModalComp(
      <IssueTicketModal returnData={load} id={id} type='imported'  />
    ))

  }

  return (
    <div className='flex justify-between w-full relative'>
      <p className="flex-1 overflow-hidden overflow-ellipsis" title={params.value}>
        {params.value}
      </p>
      {!params.row.ticketedBy ? (
        // <button className='btn2' onClick={issueTicket}>Issue Ticket</button>

        <DotMenu anchor={anchorEl} setAnchor={setAnchorEl} parentControl={true} >
          {/* <MenuItem><Link to={`/flights/details/${params.id}`}>View Detail</Link></MenuItem> */}
          <MenuItem onClick={issueTicket}>Issue Ticket</MenuItem>
        </DotMenu>
      ): null}
    </div>
  )
}


export default function Index() {
  const [data,setData] = useState(rows);
  const [loading,setLoading] = useState(true);
  const [filter,setFilter] = useState(filterItems[0]);
  const [paginationModel,setPaginationModel] = useState({pageSize: 25,page: 0})
  const [type,setType] = useState("");

  const [PNR,setPNR] = useState('');
  const dispatch = useDispatch();
  // const [PNRData,setPNRData] = useState();
  // const [rowId,setRowId] = useState(null);
  const apiRef = useGridApiRef();
  var [fetchedData,setFetchedData] = useState(null);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  
  async function load() {
    const res = await getImportedPNRs();
    setLoading(false);
    if(res.return) {
      // console.log("here",res.data);
      let data = res.data.map((row) => templateImport(row))
      setFetchedData(data);
      setData(data);
    }
  }

  const columns = [
    {field: 'customer',headerName: 'Customer',flex:1,minWidth: 200,
      renderCell: (params) => {
        if(!params.value) return null;
        return (
          <Link to={`/flights/details/${params.id}`}>
  
            <div className='flex gap-4 items-center'>
              <Avatar>{params.value[0]}</Avatar>
              {params.value}
            </div>
          </Link>
        )
      }
    },
    {field: 'bookingDate',headerName: 'Booking Date',minWidth: 150,
      renderCell: (params) => {
        if(!params.value)
          return null;
  
        return (
          <div className='flex flex-col'>
            {(new Date(params.value)).toDateString()}
            <small>{(new Date(params.value)).toLocaleTimeString()}</small>
          </div>
        )
      }
    },
    {field: 'tripDetails',headerName: 'Trip Details',minWidth: 170,
      renderCell: (params) => {
        if(!params.value) return null;
        return (
          <div className='flex flex-col'>
            {params.value.airline}
            <small>{params.value.path.origin} - {params.value.path.destination}</small>
          </div>
        )
      }
    },
    {field: 'departureDate', headerName: 'Flight Date',minWidth: 130,
      renderCell: (params) => {
        if(!params.value)
          return null;
  
        return (
          <div className='flex flex-col '>
            {moment(params.value.date, 'DDMMYY').format('YYYY-MM-DD')}
            <small>{moment(params.value.time, 'HHmm').format('HH:mm')}</small>
          </div>
        )
      }
    },
    // {field: 'returnDate', headerName: 'Return Date',minWidth: 130,
    //   renderCell: (params) => {
    //     if(!params.value)
    //       return null;
  
    //     return (
    //       <div className='flex flex-col '>
    //         {params.value.date}
    //         <small>{params.value.time}</small>
    //       </div>
    //     )
    //   }
    // },
    {field: 'PNR', headerName: 'PNR',
      renderCell: (params) => {
        if(!params.value) return null;
        
        return (
          <Link to={`/flights/details/${params.id}`}>
          <div className='flex flex-col gap-0'>  
            <b className='text-theme1 !font-normal'>{params.value}</b>
          </div>
          </Link>
        )
      },
    },
    {field: 'amount',headerName: 'Amount',
      renderCell: (params) => {
        if(!params.value)
          return null;
  
        return (
          <div>
            {formatMoney(params.value)}
          </div>
        )
      }
    },
    {field: 'status',headerName: 'Status',flex: 1,minWidth: 150,
      renderCell: (params) => {
        // if(!params.value)
        //   return null;
  
        return <StatusCol params={params} load={load} />
      }
    },
  ];
  

  async function importPNR() {
    setLoading(true)
    const res = await importPNRReq(PNR)
    setLoading(false)
    if(res.return) {
      let data = templateImport(res.data)
      setFetchedData(data);
      console.log(" -- ",data)
      let prev = fetchedData || [];
      setData([...prev,data]);

    } else
      dispatch(setAlertMsg(['error',res.msg]))
  }

  function filterType(val) {
    setType(val);
    setData(fetchedData);
    val = val.toLowerCase();
    if(val === "all") return true;
    
    setData(data => data.filter(obj => (
      obj.bookedBy.type.toLowerCase() === val.toLowerCase()
    )))
  }

  function handleSearch(val) {
    setData(fetchedData);

    val = val.toLowerCase();
    setData(data => data.filter(obj => (
      obj?.customer?.toLowerCase()?.includes(val) ||
      (new Date(obj?.bookingDate))?.toDateString()?.toLowerCase()?.includes(val) ||
      obj?.PNR?.id?.toLowerCase()?.includes(val) ||
      obj?.PNR?.code?.toLowerCase()?.includes(val) ||
      obj?.tripDetails?.airline?.toLowerCase()?.includes(val) ||
      obj?.tripDetails?.path?.origin?.toLowerCase()?.includes(val) ||
      obj?.tripDetails?.path?.destination?.toLowerCase()?.includes(val) ||
      (parseInt(obj?.amount) <= val) ||
      obj?.status?.includes(val)
    )))
  }

  return (
    <div className='w-full p-4 bg-[#f3f3f3]'>
    <div className='w-full p-4 bg-secondary'>
      <div className='p-5 flex flex-col gap-4'>
        <h5>Enter PNR to retrieve</h5>
        <div className='flex gap-4 items-stretch'>
          <input className='w-3/4 bg-primary/10 px-2' type='text' 
            value={PNR}
            onChange={(ev) => setPNR(ev.target.value)}
           />
          <div className='w-1/4'>
            <button className='btn2 w-full flex items-center gap-2 ' onClick={importPNR}>
              {
                loading ? (
                  <div className='load'></div>
                ) : null
              }
              Import PNR</button>
          </div>
        </div>
      </div>
      {/* <div className='py-5'>
        <TextField select value={type} onChange={(ev) => filterType(ev.target.value)} label="Select Customer,Agent or Company" size='small' className='min-w-[350px]'>
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="Customer">Customer</MenuItem>
          <MenuItem value="Sub_Agent">Agent</MenuItem>
          <MenuItem value="Corporate">Company</MenuItem>
        </TextField>
      </div> */}
      <div className='flex gap-4 justify-between items-center my-3'>
        <Tabs value={filter.items[0].id} onChange={(ev,newVal) => setFilter(filterItems[newVal])} 
         variant='scrollable'
         scrollButtons={false}
         className='shadow-md'>
          <Tab label={`Imported (${data.length})`} />
          <Tab label="Ticketed" />
          {/* <Tab label="Booked" />
          <Tab label="Cancelled" />
          <Tab label="Refund" /> */}
        </Tabs>
        <SearchInput className='text-theme1' onChange={handleSearch} />
        <button className='btn2 whitespace-nowrap'><ArticleOutlined /> Generate Report</button>
      </div>
      <Box className=''>
        <DataGrid rows={data} columns={columns} loading={loading} autoHeight hideFooter={false}
          // getRowId={(row) => row.id}
          apiRef={apiRef}
          initialState={{
            sorting: {
              sortModel: [{field: "bookingDate",sort: 'desc'}]
            },
          }}
          filterModel={filter}
          onFilterModelChange={(newFilter) => setFilter(newFilter)}
          
          // slots={{pagination: Pagination}}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}

          // onCellEditStart={params => setRowId(params.id)}
          // hideFooterPagination
          checkboxSelection
        />
        <Pagination perPage={10} total={46} apiRef={apiRef} />
      </Box>
      </div>
    </div>
  )
}
