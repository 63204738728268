export const importPNRTemp = {
  "id": "4375b4a4-4788-4a15-8234-b4d674ef7288",
  "pnrCode": "KN7IFT",
  "pnrData": {
      "activePNRimage": {
          "amadeusId": {
              "reservation": {
                  "controlNumber": "KN7IFT"
              }
          },
          "pnrHeader": {
              "creditLine": {
                  "officeData": {
                      "officeId": "LOSN828HR",
                      "agentCode": "9997WSSU"
                  }
              },
              "sbrPosDetails": {
                  "sbrPreferences": {
                      "userPreferences": {
                          "codedCountry": "NG"
                      }
                  },
                  "sbrSystemDetails": {
                      "deliveringSystem": {
                          "companyId": "1A",
                          "locationId": "LOS"
                      }
                  },
                  "sbrUserIdentification": {
                      "originatorTypeCode": "T",
                      "originIdentification": {
                          "originatorId": "59210421",
                          "inHouseIdentification1": "LOSN828HR"
                      }
                  }
              },
              "technicalData": {
                  "number": "3",
                  "pnrPurgeDate": "140923"
              },
              "relatedProduct": {
                  "quantity": "2"
              },
              "reservationInfo": {
                  "reservation": {
                      "date": "140723",
                      "time": "1016",
                      "companyId": "1A",
                      "controlType": "A",
                      "controlNumber": "KN7IFT"
                  }
              },
              "securityInformation": {
                  "queueingInformation": {
                      "queueingOfficeId": "LOSN828HR"
                  },
                  "secondRpInformation": {
                      "creationDate": "140723",
                      "creationTime": "1015",
                      "agentSignature": "9997WS",
                      "creatorIataCode": "59210421",
                      "creationOfficeId": "LOSN828HR"
                  },
                  "responsibilityInformation": {
                      "agentId": "WSSU",
                      "iataCode": "59210421",
                      "officeId": "LOSN828HR",
                      "typeOfPnrElement": "RP"
                  }
              },
              "sbrUpdatorPosDetails": {
                  "sbrPreferences": {
                      "userPreferences": {
                          "codedCountry": "DE"
                      }
                  },
                  "sbrSystemDetails": {
                      "deliveringSystem": {
                          "companyId": "LH",
                          "locationId": "FRA"
                      }
                  },
                  "sbrUserIdentification": {
                      "originatorTypeCode": "A",
                      "originIdentification": {
                          "inHouseIdentification1": "FRALH07RI"
                      }
                  }
              },
              "sbrCreationPosDetails": {
                  "sbrPreferences": {
                      "userPreferences": {
                          "codedCountry": "NG"
                      }
                  },
                  "sbrSystemDetails": {
                      "deliveringSystem": {
                          "companyId": "1A",
                          "locationId": "LOS"
                      }
                  },
                  "sbrUserIdentification": {
                      "originatorTypeCode": "T",
                      "originIdentification": {
                          "originatorId": "59210421",
                          "inHouseIdentification1": "LOSN828HR"
                      }
                  }
              }
          },
          "travellerInfo": [
              {
                  "passengerData": [
                      {
                          "dateOfBirth": {
                              "dateAndTimeDetails": {
                                  "date": "121296"
                              }
                          },
                          "travellerInformation": {
                              "passenger": {
                                  "type": "ADT",
                                  "firstName": "JAHANZAIB MR",
                                  "infantIndicator": "1"
                              },
                              "traveller": {
                                  "surname": "AKBAR",
                                  "quantity": "1"
                              }
                          }
                      },
                      {
                          "dateOfBirth": {
                              "dateAndTimeDetails": {
                                  "date": "120422"
                              }
                          },
                          "travellerInformation": {
                              "passenger": {
                                  "type": "INF",
                                  "firstName": "KIRAN"
                              },
                              "traveller": {
                                  "surname": "ILYAS",
                                  "quantity": "1"
                              }
                          }
                      }
                  ],
                  "elementManagementPassenger": {
                      "lineNumber": "1",
                      "segmentName": "NM",
                      "elementReference": {
                          "number": "2",
                          "qualifier": "PT"
                      }
                  }
              },
              {
                  "passengerData": {
                      "dateOfBirth": {
                          "dateAndTimeDetails": {
                              "date": "310119"
                          }
                      },
                      "travellerInformation": {
                          "passenger": {
                              "type": "CHD",
                              "firstName": "AMNA MS"
                          },
                          "traveller": {
                              "surname": "ILYAS",
                              "quantity": "1"
                          }
                      }
                  },
                  "elementManagementPassenger": {
                      "lineNumber": "2",
                      "segmentName": "NM",
                      "elementReference": {
                          "number": "4",
                          "qualifier": "PT"
                      }
                  }
              }
          ],
          "dataElementsMaster": {
              "marker2": [],
              "marker3": [],
              "dataElementsIndiv": [
                  {
                      "otherDataFreetext": {
                          "longFreetext": "JAHANZAIBZAIBI577@GMAIL.COM",
                          "freetextDetail": {
                              "type": "P02",
                              "subjectQualifier": "3"
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "5",
                          "segmentName": "AP",
                          "elementReference": {
                              "number": "6",
                              "qualifier": "OT"
                          }
                      }
                  },
                  {
                      "otherDataFreetext": {
                          "longFreetext": "JAHANZAIBZAIBI577@GMAIL.COM",
                          "freetextDetail": {
                              "type": "5",
                              "subjectQualifier": "3"
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "4",
                          "segmentName": "AP",
                          "elementReference": {
                              "number": "7",
                              "qualifier": "OT"
                          }
                      }
                  },
                  {
                      "otherDataFreetext": {
                          "longFreetext": "PAX FARE RESTRICTION MAY APPLY",
                          "freetextDetail": {
                              "type": "10",
                              "subjectQualifier": "3"
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "16",
                          "segmentName": "FE",
                          "elementReference": {
                              "number": "13",
                              "qualifier": "OT"
                          }
                      },
                      "referenceForDataElement": {
                          "reference": [
                              {
                                  "number": "2",
                                  "qualifier": "PT"
                              },
                              {
                                  "number": "1",
                                  "qualifier": "ST"
                              }
                          ]
                      }
                  },
                  {
                      "otherDataFreetext": {
                          "longFreetext": "PAX FARE RESTRICTION MAY APPLY",
                          "freetextDetail": {
                              "type": "10",
                              "subjectQualifier": "3"
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "17",
                          "segmentName": "FE",
                          "elementReference": {
                              "number": "15",
                              "qualifier": "OT"
                          }
                      },
                      "referenceForDataElement": {
                          "reference": [
                              {
                                  "number": "4",
                                  "qualifier": "PT"
                              },
                              {
                                  "number": "1",
                                  "qualifier": "ST"
                              }
                          ]
                      }
                  },
                  {
                      "otherDataFreetext": {
                          "longFreetext": "INF FARE RESTRICTION MAY APPLY",
                          "freetextDetail": {
                              "type": "10",
                              "subjectQualifier": "3"
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "18",
                          "segmentName": "FE",
                          "elementReference": {
                              "number": "17",
                              "qualifier": "OT"
                          }
                      },
                      "referenceForDataElement": {
                          "reference": [
                              {
                                  "number": "2",
                                  "qualifier": "PT"
                              },
                              {
                                  "number": "1",
                                  "qualifier": "ST"
                              }
                          ]
                      }
                  },
                  {
                      "otherDataFreetext": {
                          "longFreetext": "PAX *M*5",
                          "freetextDetail": {
                              "type": "11",
                              "subjectQualifier": "3"
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "19",
                          "segmentName": "FM",
                          "elementReference": {
                              "number": "19",
                              "qualifier": "OT"
                          }
                      }
                  },
                  {
                      "otherDataFreetext": {
                          "longFreetext": "CASH",
                          "freetextDetail": {
                              "type": "16",
                              "subjectQualifier": "3"
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "20",
                          "segmentName": "FP",
                          "elementReference": {
                              "number": "9",
                              "qualifier": "OT"
                          }
                      }
                  },
                  {
                      "otherDataFreetext": {
                          "longFreetext": "PAX LH",
                          "freetextDetail": {
                              "type": "P18",
                              "subjectQualifier": "3"
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "21",
                          "segmentName": "FV",
                          "elementReference": {
                              "number": "14",
                              "qualifier": "OT"
                          }
                      },
                      "referenceForDataElement": {
                          "reference": [
                              {
                                  "number": "2",
                                  "qualifier": "PT"
                              },
                              {
                                  "number": "1",
                                  "qualifier": "ST"
                              }
                          ]
                      }
                  },
                  {
                      "otherDataFreetext": {
                          "longFreetext": "PAX LH",
                          "freetextDetail": {
                              "type": "P18",
                              "subjectQualifier": "3"
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "22",
                          "segmentName": "FV",
                          "elementReference": {
                              "number": "16",
                              "qualifier": "OT"
                          }
                      },
                      "referenceForDataElement": {
                          "reference": [
                              {
                                  "number": "4",
                                  "qualifier": "PT"
                              },
                              {
                                  "number": "1",
                                  "qualifier": "ST"
                              }
                          ]
                      }
                  },
                  {
                      "otherDataFreetext": {
                          "longFreetext": "INF LH",
                          "freetextDetail": {
                              "type": "P18",
                              "subjectQualifier": "3"
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "23",
                          "segmentName": "FV",
                          "elementReference": {
                              "number": "18",
                              "qualifier": "OT"
                          }
                      },
                      "referenceForDataElement": {
                          "reference": [
                              {
                                  "number": "2",
                                  "qualifier": "PT"
                              },
                              {
                                  "number": "1",
                                  "qualifier": "ST"
                              }
                          ]
                      }
                  },
                  {
                      "miscellaneousRemarks": {
                          "remarks": {
                              "type": "RM",
                              "category": "Z",
                              "freetext": "CONF*FORMAT:PDF"
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "15",
                          "segmentName": "RM",
                          "elementReference": {
                              "number": "10",
                              "qualifier": "OT"
                          }
                      }
                  },
                  {
                      "miscellaneousRemarks": {
                          "remarks": {
                              "type": "RM",
                              "freetext": "GENIUS MIND ZONETRAVEL"
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "12",
                          "segmentName": "RM",
                          "elementReference": {
                              "number": "11",
                              "qualifier": "OT"
                          }
                      }
                  },
                  {
                      "miscellaneousRemarks": {
                          "remarks": {
                              "type": "RM",
                              "freetext": "H-ADULT FARE - 2"
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "13",
                          "segmentName": "RM",
                          "elementReference": {
                              "number": "20",
                              "qualifier": "OT"
                          }
                      }
                  },
                  {
                      "miscellaneousRemarks": {
                          "remarks": {
                              "type": "RM",
                              "freetext": "PAYMENT METHOD: CASH"
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "14",
                          "segmentName": "RM",
                          "elementReference": {
                              "number": "21",
                              "qualifier": "OT"
                          }
                      }
                  },
                  {
                      "pnrSecurity": {
                          "security": {
                              "accessMode": "B",
                              "identification": "YTOC4240M"
                          },
                          "indicator": "G",
                          "securityInfo": {
                              "officeId": "LOSN828HR",
                              "agentCode": "WSSU",
                              "creationDate": "140723"
                          }
                      },
                      "elementManagementData": {
                          "segmentName": "ES",
                          "elementReference": {
                              "number": "8",
                              "qualifier": "OT"
                          }
                      }
                  },
                  {
                      "serviceRequest": {
                          "ssr": {
                              "type": "INFT",
                              "status": "HK",
                              "freeText": "ILYAS/KIRAN 12APR22",
                              "quantity": "1",
                              "companyId": "LH"
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "7",
                          "segmentName": "SSR",
                          "elementReference": {
                              "number": "4",
                              "qualifier": "OT"
                          }
                      },
                      "referenceForDataElement": {
                          "reference": [
                              {
                                  "number": "2",
                                  "qualifier": "PT"
                              },
                              {
                                  "number": "1",
                                  "qualifier": "ST"
                              }
                          ]
                      }
                  },
                  {
                      "serviceRequest": {
                          "ssr": {
                              "type": "CHLD",
                              "status": "HK",
                              "freeText": "31JAN19",
                              "quantity": "1",
                              "companyId": "LH"
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "8",
                          "segmentName": "SSR",
                          "elementReference": {
                              "number": "5",
                              "qualifier": "OT"
                          }
                      },
                      "referenceForDataElement": {
                          "reference": [
                              {
                                  "number": "4",
                                  "qualifier": "PT"
                              },
                              {
                                  "number": "1",
                                  "qualifier": "ST"
                              }
                          ]
                      }
                  },
                  {
                      "serviceRequest": {
                          "ssr": {
                              "type": "BLML",
                              "status": "NO",
                              "freeText": "NOT SUPPORTED",
                              "quantity": "1",
                              "companyId": "LH"
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "9",
                          "segmentName": "SSR",
                          "elementReference": {
                              "number": "22",
                              "qualifier": "OT"
                          }
                      },
                      "referenceForDataElement": {
                          "reference": [
                              {
                                  "number": "2",
                                  "qualifier": "PT"
                              },
                              {
                                  "number": "1",
                                  "qualifier": "ST"
                              }
                          ]
                      }
                  },
                  {
                      "serviceRequest": {
                          "ssr": {
                              "type": "OTHS",
                              "freeText": [
                                  "PLS ADV TKT NBR BY 17JUL23/1015Z OR LH OPTG/MKTG FLTS WILL BE CANX / A",
                                  "PPLIC FARE RULE APPLIES IF IT DEMANDS"
                              ],
                              "companyId": "1A"
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "10",
                          "segmentName": "SSR",
                          "elementReference": {
                              "number": "26",
                              "qualifier": "OT"
                          }
                      }
                  },
                  {
                      "serviceRequest": {
                          "ssr": {
                              "type": "OTHS",
                              "freeText": "/// EARLIER TKTG",
                              "companyId": "1A"
                          }
                      },
                      "elementManagementData": {
                          "lineNumber": "11",
                          "segmentName": "SSR",
                          "elementReference": {
                              "number": "27",
                              "qualifier": "OT"
                          }
                      }
                  },
                  {
                      "ticketElement": {
                          "ticket": {
                              "date": "140723",
                              "officeId": "LOSN828HR",
                              "indicator": "TL",
                              "queueNumber": "10",
                              "queueCategory": "0"
                          },
                          "passengerType": "PAX"
                      },
                      "elementManagementData": {
                          "lineNumber": "6",
                          "segmentName": "TK",
                          "elementReference": {
                              "number": "12",
                              "qualifier": "OT"
                          }
                      }
                  }
              ],
              "dataElementsStruct": [
                  {
                      "fopGroup": {
                          "fopFreeflow": {
                              "freeText": "CASH",
                              "freeTextDetails": {
                                  "source": "M",
                                  "encoding": "ZZZ",
                                  "textSubjectQualifier": "GEN"
                              }
                          },
                          "fopPNRDetails": {
                              "fopDetails": {
                                  "fopCode": "STD",
                                  "fopStatus": "N"
                              }
                          },
                          "fopSequenceNumber": {
                              "sequenceDetails": {
                                  "number": "1"
                              }
                          }
                      },
                      "elementManagementStructData": {
                          "lineNumber": "20",
                          "segmentName": "FP",
                          "elementReference": {
                              "number": "9",
                              "qualifier": "OT"
                          }
                      }
                  },
                  {
                      "idElements": {
                          "idsValue": {
                              "idSection": {
                                  "primeId": "5109E2350000FA44",
                                  "referenceQualifier": "UCI"
                              }
                          }
                      },
                      "elementManagementStructData": {
                          "segmentName": "UCI",
                          "elementReference": {
                              "number": "0",
                              "qualifier": "OT"
                          }
                      },
                      "referenceForStructDataElement": {
                          "reference": {
                              "number": "2",
                              "qualifier": "PT"
                          }
                      }
                  },
                  {
                      "idElements": {
                          "idsValue": {
                              "idSection": {
                                  "primeId": "5109E2350000FA45",
                                  "referenceQualifier": "UCI"
                              }
                          }
                      },
                      "elementManagementStructData": {
                          "segmentName": "UCI",
                          "elementReference": {
                              "number": "0",
                              "qualifier": "OT"
                          }
                      },
                      "referenceForStructDataElement": {
                          "reference": {
                              "number": "4",
                              "qualifier": "PT"
                          }
                      }
                  }
              ]
          },
          "pricingRecordGroup": {
              "listOfTSTs": [
                  {
                      "taxInfo": [
                          {
                              "taxDetails": {
                                  "taxType": {
                                      "isoCountry": "YQ"
                                  },
                                  "taxNature": "AD",
                                  "taxQualifier": "7",
                                  "taxIdentification": {
                                      "taxIdentifier": "X"
                                  }
                              },
                              "amountDetails": {
                                  "fareDataMainInformation": {
                                      "fareAmount": "61239",
                                      "fareCurrency": "NGN",
                                      "fareDataQualifier": "TAX"
                                  }
                              }
                          },
                          {
                              "taxDetails": {
                                  "taxType": {
                                      "isoCountry": "YR"
                                  },
                                  "taxNature": "VB",
                                  "taxQualifier": "7",
                                  "taxIdentification": {
                                      "taxIdentifier": "X"
                                  }
                              },
                              "amountDetails": {
                                  "fareDataMainInformation": {
                                      "fareAmount": "14289",
                                      "fareCurrency": "NGN",
                                      "fareDataQualifier": "TAX"
                                  }
                              }
                          },
                          {
                              "taxDetails": {
                                  "taxType": {
                                      "isoCountry": "NG"
                                  },
                                  "taxNature": "VE",
                                  "taxQualifier": "7",
                                  "taxIdentification": {
                                      "taxIdentifier": "X"
                                  }
                              },
                              "amountDetails": {
                                  "fareDataMainInformation": {
                                      "fareAmount": "31456",
                                      "fareCurrency": "NGN",
                                      "fareDataQualifier": "TAX"
                                  }
                              }
                          },
                          {
                              "taxDetails": {
                                  "taxType": {
                                      "isoCountry": "QT"
                                  },
                                  "taxNature": "AE",
                                  "taxQualifier": "7",
                                  "taxIdentification": {
                                      "taxIdentifier": "X"
                                  }
                              },
                              "amountDetails": {
                                  "fareDataMainInformation": {
                                      "fareAmount": "74407",
                                      "fareCurrency": "NGN",
                                      "fareDataQualifier": "TAX"
                                  }
                              }
                          },
                          {
                              "taxDetails": {
                                  "taxType": {
                                      "isoCountry": "TE"
                                  },
                                  "taxNature": "SE",
                                  "taxQualifier": "7",
                                  "taxIdentification": {
                                      "taxIdentifier": "X"
                                  }
                              },
                              "amountDetails": {
                                  "fareDataMainInformation": {
                                      "fareAmount": "14882",
                                      "fareCurrency": "NGN",
                                      "fareDataQualifier": "TAX"
                                  }
                              }
                          }
                      ],
                      "tktDate": [
                          {
                              "dateTime": {
                                  "day": "14",
                                  "year": "2023",
                                  "month": "7"
                              },
                              "businessSemantic": "PD"
                          },
                          {
                              "dateTime": {
                                  "day": "17",
                                  "year": "2023",
                                  "month": "7"
                              },
                              "businessSemantic": "LT"
                          }
                      ],
                      "bankerRates": {
                          "firstRateDetail": {
                              "amount": "744.07"
                          }
                      },
                      "tstRecordId": {
                          "uniqueReference": "1"
                      },
                      "paxReference": {
                          "referenceDetails": {
                              "type": "P",
                              "value": "2"
                          }
                      },
                      "securityInfo": {
                          "officeDetails": {
                              "originIdentification": {
                                  "agentsignin": "WS",
                                  "confidentialOffice": "LOSN828HR"
                              }
                          }
                      },
                      "itineraryInfo": {
                          "fareQualifier": {
                              "fareBasisDetails": {
                                  "primaryCode": "WNC",
                                  "fareBasisCode": "OWNG",
                                  "discTktDesignator": "ADT"
                              }
                          },
                          "segmentReference": {
                              "refDetails": {
                                  "refNumber": "1",
                                  "refQualifier": "S"
                              }
                          },
                          "connexInformation": {
                              "connecDetails": {
                                  "connexType": "O"
                              }
                          },
                          "validityInformation": [
                              {
                                  "dateTime": {
                                      "day": "10",
                                      "year": "2023",
                                      "month": "9"
                                  },
                                  "businessSemantic": "A"
                              },
                              {
                                  "dateTime": {
                                      "day": "10",
                                      "year": "2023",
                                      "month": "9"
                                  },
                                  "businessSemantic": "B"
                              }
                          ],
                          "bagAllowanceInformation": {
                              "bagAllowanceDetails": {
                                  "baggageType": "N",
                                  "baggageQuantity": "1"
                              }
                          }
                      },
                      "generalTSTinfo": {
                          "fcmi": "0",
                          "tstNumber": "1",
                          "tstInformation": {
                              "tstIndicator": "A"
                          }
                      },
                      "otherPricingInfo": {
                          "attributeDetails": [
                              {
                                  "attributeType": "FCA",
                                  "attributeDescription": "LOS LH FRA744.00NUC744.00END ROE1.000000"
                              },
                              {
                                  "attributeType": "CRE",
                                  "attributeDescription": "FXP"
                              }
                          ]
                      },
                      "statusInformation": {
                          "firstStatusDetails": {
                              "tstFlag": "ADT"
                          },
                          "otherStatusDetails": {
                              "tstFlag": "INT"
                          }
                      },
                      "fareDataInformation": {
                          "fareDataSupInformation": [
                              {
                                  "fareAmount": "744.00",
                                  "fareCurrency": "USD",
                                  "fareDataQualifier": "B"
                              },
                              {
                                  "fareAmount": "553588",
                                  "fareCurrency": "NGN",
                                  "fareDataQualifier": "E"
                              },
                              {
                                  "fareAmount": "749861",
                                  "fareCurrency": "NGN",
                                  "fareDataQualifier": "T"
                              },
                              {
                                  "fareAmount": "749861",
                                  "fareCurrency": "NGN",
                                  "fareDataQualifier": "GT"
                              }
                          ],
                          "fareDataMainInformation": {
                              "fareDataQualifier": "F"
                          }
                      },
                      "fareOriginDestination": {
                          "origin": "LOS",
                          "destination": "FRA"
                      }
                  },
                  {
                      "taxInfo": [
                          {
                              "taxDetails": {
                                  "taxType": {
                                      "isoCountry": "YQ"
                                  },
                                  "taxNature": "AD",
                                  "taxQualifier": "7",
                                  "taxIdentification": {
                                      "taxIdentifier": "X"
                                  }
                              },
                              "amountDetails": {
                                  "fareDataMainInformation": {
                                      "fareAmount": "61239",
                                      "fareCurrency": "NGN",
                                      "fareDataQualifier": "TAX"
                                  }
                              }
                          },
                          {
                              "taxDetails": {
                                  "taxType": {
                                      "isoCountry": "YR"
                                  },
                                  "taxNature": "VB",
                                  "taxQualifier": "7",
                                  "taxIdentification": {
                                      "taxIdentifier": "X"
                                  }
                              },
                              "amountDetails": {
                                  "fareDataMainInformation": {
                                      "fareAmount": "14289",
                                      "fareCurrency": "NGN",
                                      "fareDataQualifier": "TAX"
                                  }
                              }
                          },
                          {
                              "taxDetails": {
                                  "taxType": {
                                      "isoCountry": "NG"
                                  },
                                  "taxNature": "VE",
                                  "taxQualifier": "7",
                                  "taxIdentification": {
                                      "taxIdentifier": "X"
                                  }
                              },
                              "amountDetails": {
                                  "fareDataMainInformation": {
                                      "fareAmount": "24536",
                                      "fareCurrency": "NGN",
                                      "fareDataQualifier": "TAX"
                                  }
                              }
                          },
                          {
                              "taxDetails": {
                                  "taxType": {
                                      "isoCountry": "QT"
                                  },
                                  "taxNature": "AE",
                                  "taxQualifier": "7",
                                  "taxIdentification": {
                                      "taxIdentifier": "X"
                                  }
                              },
                              "amountDetails": {
                                  "fareDataMainInformation": {
                                      "fareAmount": "74407",
                                      "fareCurrency": "NGN",
                                      "fareDataQualifier": "TAX"
                                  }
                              }
                          },
                          {
                              "taxDetails": {
                                  "taxType": {
                                      "isoCountry": "TE"
                                  },
                                  "taxNature": "SE",
                                  "taxQualifier": "7",
                                  "taxIdentification": {
                                      "taxIdentifier": "X"
                                  }
                              },
                              "amountDetails": {
                                  "fareDataMainInformation": {
                                      "fareAmount": "14882",
                                      "fareCurrency": "NGN",
                                      "fareDataQualifier": "TAX"
                                  }
                              }
                          }
                      ],
                      "tktDate": [
                          {
                              "dateTime": {
                                  "day": "14",
                                  "year": "2023",
                                  "month": "7"
                              },
                              "businessSemantic": "PD"
                          },
                          {
                              "dateTime": {
                                  "day": "17",
                                  "year": "2023",
                                  "month": "7"
                              },
                              "businessSemantic": "LT"
                          }
                      ],
                      "bankerRates": {
                          "firstRateDetail": {
                              "amount": "744.07"
                          }
                      },
                      "tstRecordId": {
                          "uniqueReference": "2"
                      },
                      "paxReference": {
                          "referenceDetails": {
                              "type": "P",
                              "value": "4"
                          }
                      },
                      "securityInfo": {
                          "officeDetails": {
                              "originIdentification": {
                                  "agentsignin": "WS",
                                  "confidentialOffice": "LOSN828HR"
                              }
                          }
                      },
                      "itineraryInfo": {
                          "fareQualifier": {
                              "fareBasisDetails": {
                                  "primaryCode": "WNC",
                                  "fareBasisCode": "OWNG",
                                  "ticketDesignator": "CH25",
                                  "discTktDesignator": "CH"
                              }
                          },
                          "segmentReference": {
                              "refDetails": {
                                  "refNumber": "1",
                                  "refQualifier": "S"
                              }
                          },
                          "connexInformation": {
                              "connecDetails": {
                                  "connexType": "O"
                              }
                          },
                          "validityInformation": [
                              {
                                  "dateTime": {
                                      "day": "10",
                                      "year": "2023",
                                      "month": "9"
                                  },
                                  "businessSemantic": "A"
                              },
                              {
                                  "dateTime": {
                                      "day": "10",
                                      "year": "2023",
                                      "month": "9"
                                  },
                                  "businessSemantic": "B"
                              }
                          ],
                          "bagAllowanceInformation": {
                              "bagAllowanceDetails": {
                                  "baggageType": "N",
                                  "baggageQuantity": "1"
                              }
                          }
                      },
                      "generalTSTinfo": {
                          "fcmi": "0",
                          "tstNumber": "2",
                          "tstInformation": {
                              "tstIndicator": "A"
                          }
                      },
                      "otherPricingInfo": {
                          "attributeDetails": [
                              {
                                  "attributeType": "FCA",
                                  "attributeDescription": "LOS LH FRA558.00NUC558.00END ROE1.000000"
                              },
                              {
                                  "attributeType": "CRE",
                                  "attributeDescription": "FXP"
                              }
                          ]
                      },
                      "statusInformation": {
                          "firstStatusDetails": {
                              "tstFlag": "ADT"
                          },
                          "otherStatusDetails": {
                              "tstFlag": "INT"
                          }
                      },
                      "fareDataInformation": {
                          "fareDataSupInformation": [
                              {
                                  "fareAmount": "558.00",
                                  "fareCurrency": "USD",
                                  "fareDataQualifier": "B"
                              },
                              {
                                  "fareAmount": "415191",
                                  "fareCurrency": "NGN",
                                  "fareDataQualifier": "E"
                              },
                              {
                                  "fareAmount": "604544",
                                  "fareCurrency": "NGN",
                                  "fareDataQualifier": "T"
                              },
                              {
                                  "fareAmount": "604544",
                                  "fareCurrency": "NGN",
                                  "fareDataQualifier": "GT"
                              }
                          ],
                          "fareDataMainInformation": {
                              "fareDataQualifier": "F"
                          }
                      },
                      "fareOriginDestination": {
                          "origin": "LOS",
                          "destination": "FRA"
                      }
                  },
                  {
                      "taxInfo": {
                          "taxDetails": {
                              "taxType": {
                                  "isoCountry": "NG"
                              },
                              "taxNature": "VE",
                              "taxQualifier": "7",
                              "taxIdentification": {
                                  "taxIdentifier": "X"
                              }
                          },
                          "amountDetails": {
                              "fareDataMainInformation": {
                                  "fareAmount": "2753",
                                  "fareCurrency": "NGN",
                                  "fareDataQualifier": "TAX"
                              }
                          }
                      },
                      "tktDate": [
                          {
                              "dateTime": {
                                  "day": "14",
                                  "year": "2023",
                                  "month": "7"
                              },
                              "businessSemantic": "PD"
                          },
                          {
                              "dateTime": {
                                  "day": "17",
                                  "year": "2023",
                                  "month": "7"
                              },
                              "businessSemantic": "LT"
                          }
                      ],
                      "bankerRates": {
                          "firstRateDetail": {
                              "amount": "744.07"
                          }
                      },
                      "tstRecordId": {
                          "uniqueReference": "3"
                      },
                      "paxReference": {
                          "referenceDetails": {
                              "type": "P",
                              "value": "2"
                          }
                      },
                      "securityInfo": {
                          "officeDetails": {
                              "originIdentification": {
                                  "agentsignin": "WS",
                                  "confidentialOffice": "LOSN828HR"
                              }
                          }
                      },
                      "itineraryInfo": {
                          "fareQualifier": {
                              "fareBasisDetails": {
                                  "primaryCode": "WNC",
                                  "fareBasisCode": "OWNG",
                                  "ticketDesignator": "IN90",
                                  "discTktDesignator": "IN"
                              }
                          },
                          "segmentReference": {
                              "refDetails": {
                                  "refNumber": "1",
                                  "refQualifier": "S"
                              }
                          },
                          "connexInformation": {
                              "connecDetails": {
                                  "connexType": "O"
                              }
                          },
                          "validityInformation": [
                              {
                                  "dateTime": {
                                      "day": "10",
                                      "year": "2023",
                                      "month": "9"
                                  },
                                  "businessSemantic": "A"
                              },
                              {
                                  "dateTime": {
                                      "day": "10",
                                      "year": "2023",
                                      "month": "9"
                                  },
                                  "businessSemantic": "B"
                              }
                          ],
                          "bagAllowanceInformation": {
                              "bagAllowanceDetails": {
                                  "baggageType": "N",
                                  "baggageQuantity": "1"
                              }
                          }
                      },
                      "generalTSTinfo": {
                          "fcmi": "0",
                          "tstNumber": "3",
                          "tstInformation": {
                              "tstIndicator": "A"
                          }
                      },
                      "otherPricingInfo": {
                          "attributeDetails": [
                              {
                                  "attributeType": "FCA",
                                  "attributeDescription": "LOS LH FRA74.40NUC74.40END ROE1.000000"
                              },
                              {
                                  "attributeType": "CRE",
                                  "attributeDescription": "FXP"
                              }
                          ]
                      },
                      "statusInformation": {
                          "firstStatusDetails": {
                              "tstFlag": "INF"
                          },
                          "otherStatusDetails": {
                              "tstFlag": "INT"
                          }
                      },
                      "fareDataInformation": {
                          "fareDataSupInformation": [
                              {
                                  "fareAmount": "74.00",
                                  "fareCurrency": "USD",
                                  "fareDataQualifier": "B"
                              },
                              {
                                  "fareAmount": "55061",
                                  "fareCurrency": "NGN",
                                  "fareDataQualifier": "E"
                              },
                              {
                                  "fareAmount": "57814",
                                  "fareCurrency": "NGN",
                                  "fareDataQualifier": "T"
                              },
                              {
                                  "fareAmount": "57814",
                                  "fareCurrency": "NGN",
                                  "fareDataQualifier": "GT"
                              }
                          ],
                          "fareDataMainInformation": {
                              "fareDataQualifier": "F"
                          }
                      },
                      "fareOriginDestination": {
                          "origin": "LOS",
                          "destination": "FRA"
                      }
                  }
              ],
              "pricingRecordData": [],
              "productPricingQuotationRecord": [
                  {
                      "pricingRecordId": {
                          "referenceType": "TST",
                          "uniqueReference": "1"
                      },
                      "ptcDiscountCode": {
                          "discountDetails": {
                              "discountCode": "ADT",
                              "unitQuantity": "1"
                          }
                      },
                      "passengerTattoos": {
                          "passengerReference": {
                              "type": "PA",
                              "value": "2"
                          }
                      },
                      "documentDetailsGroup": {
                          "flags": {
                              "statusDetails": {
                                  "action": "N",
                                  "indicator": "ISD"
                              }
                          },
                          "totalFare": {
                              "monetaryDetails": {
                                  "amount": "749861",
                                  "currency": "NGN",
                                  "typeQualifier": "T"
                              }
                          },
                          "otherDates": [
                              {
                                  "dateTime": {
                                      "day": "14",
                                      "year": "2023",
                                      "month": "7"
                                  },
                                  "businessSemantic": "PD"
                              },
                              {
                                  "dateTime": {
                                      "day": "17",
                                      "year": "2023",
                                      "month": "7"
                                  },
                                  "businessSemantic": "LT"
                              }
                          ],
                          "otherFares": [
                              {
                                  "monetaryDetails": {
                                      "amount": "744.00",
                                      "currency": "USD",
                                      "typeQualifier": "B"
                                  }
                              },
                              {
                                  "monetaryDetails": {
                                      "amount": "744.07",
                                      "typeQualifier": "D1"
                                  }
                              },
                              {
                                  "monetaryDetails": {
                                      "amount": "553588",
                                      "currency": "NGN",
                                      "typeQualifier": "E"
                                  }
                              },
                              {
                                  "monetaryDetails": {
                                      "amount": "749861",
                                      "currency": "NGN",
                                      "typeQualifier": "GT"
                                  }
                              }
                          ],
                          "creationDate": {
                              "dateTime": {
                                  "day": "14",
                                  "year": "2023",
                                  "month": "7"
                              },
                              "businessSemantic": "CRD"
                          },
                          "taxInformation": [
                              {
                                  "taxDetails": {
                                      "rate": "61239",
                                      "type": "AD",
                                      "countryCode": "YQ",
                                      "currencyCode": "NGN"
                                  },
                                  "taxCategory": "X"
                              },
                              {
                                  "taxDetails": {
                                      "rate": "14289",
                                      "type": "VB",
                                      "countryCode": "YR",
                                      "currencyCode": "NGN"
                                  },
                                  "taxCategory": "X"
                              },
                              {
                                  "taxDetails": {
                                      "rate": "31456",
                                      "type": "VE",
                                      "countryCode": "NG",
                                      "currencyCode": "NGN"
                                  },
                                  "taxCategory": "X"
                              },
                              {
                                  "taxDetails": {
                                      "rate": "74407",
                                      "type": "AE",
                                      "countryCode": "QT",
                                      "currencyCode": "NGN"
                                  },
                                  "taxCategory": "X"
                              },
                              {
                                  "taxDetails": {
                                      "rate": "14882",
                                      "type": "SE",
                                      "countryCode": "TE",
                                      "currencyCode": "NGN"
                                  },
                                  "taxCategory": "X"
                              }
                          ],
                          "fareCalcRemarks": {
                              "freeText": "LOS LH FRA744.00NUC744.00END ROE1.000000",
                              "freeTextDetails": {
                                  "source": "S",
                                  "encoding": "1",
                                  "textSubjectQualifier": "FCA"
                              }
                          },
                          "issueIdentifier": {
                              "priceTariffType": "F",
                              "priceTicketDetails": {
                                  "indicators": "I"
                              }
                          },
                          "manualIndicator": {
                              "statusDetails": {
                                  "action": "N",
                                  "indicator": "M"
                              }
                          },
                          "generalIndicators": [
                              {
                                  "attributeDetails": {
                                      "attributeType": "TT",
                                      "attributeDescription": "B"
                                  }
                              },
                              {
                                  "attributeDetails": {
                                      "attributeType": "FCM",
                                      "attributeDescription": "0"
                                  }
                              }
                          ],
                          "officeInformation": {
                              "originIdentification": {
                                  "inHouseIdentification1": "LOSN828HR",
                                  "inHouseIdentification2": "WS"
                              }
                          },
                          "originDestination": {
                              "origin": "LOS",
                              "destination": "FRA"
                          },
                          "couponDetailsGroup": {
                              "productId": {
                                  "referenceDetails": {
                                      "type": "SEG",
                                      "value": "1"
                                  }
                              },
                              "fareQualifier": {
                                  "fareBasisDetails": {
                                      "rateTariffIndicator": "WNC",
                                      "otherRateTariffClass": "OWNG"
                                  }
                              },
                              "validityDates": [
                                  {
                                      "dateAndTimeDetails": {
                                          "date": "100923",
                                          "qualifier": "B"
                                      }
                                  },
                                  {
                                      "dateAndTimeDetails": {
                                          "date": "100923",
                                          "qualifier": "A"
                                      }
                                  }
                              ],
                              "baggageInformation": {
                                  "baggageDetails": {
                                      "quantityCode": "N",
                                      "freeAllowance": "1",
                                      "unitQualifier": "PC"
                                  }
                              }
                          }
                      }
                  },
                  {
                      "pricingRecordId": {
                          "referenceType": "TST",
                          "uniqueReference": "2"
                      },
                      "ptcDiscountCode": {
                          "discountDetails": {
                              "discountCode": "CHD",
                              "unitQuantity": "1"
                          }
                      },
                      "passengerTattoos": {
                          "passengerReference": {
                              "type": "PA",
                              "value": "4"
                          }
                      },
                      "documentDetailsGroup": {
                          "flags": {
                              "statusDetails": {
                                  "action": "N",
                                  "indicator": "ISD"
                              }
                          },
                          "totalFare": {
                              "monetaryDetails": {
                                  "amount": "604544",
                                  "currency": "NGN",
                                  "typeQualifier": "T"
                              }
                          },
                          "otherDates": [
                              {
                                  "dateTime": {
                                      "day": "14",
                                      "year": "2023",
                                      "month": "7"
                                  },
                                  "businessSemantic": "PD"
                              },
                              {
                                  "dateTime": {
                                      "day": "17",
                                      "year": "2023",
                                      "month": "7"
                                  },
                                  "businessSemantic": "LT"
                              }
                          ],
                          "otherFares": [
                              {
                                  "monetaryDetails": {
                                      "amount": "558.00",
                                      "currency": "USD",
                                      "typeQualifier": "B"
                                  }
                              },
                              {
                                  "monetaryDetails": {
                                      "amount": "744.07",
                                      "typeQualifier": "D1"
                                  }
                              },
                              {
                                  "monetaryDetails": {
                                      "amount": "415191",
                                      "currency": "NGN",
                                      "typeQualifier": "E"
                                  }
                              },
                              {
                                  "monetaryDetails": {
                                      "amount": "604544",
                                      "currency": "NGN",
                                      "typeQualifier": "GT"
                                  }
                              }
                          ],
                          "creationDate": {
                              "dateTime": {
                                  "day": "14",
                                  "year": "2023",
                                  "month": "7"
                              },
                              "businessSemantic": "CRD"
                          },
                          "taxInformation": [
                              {
                                  "taxDetails": {
                                      "rate": "61239",
                                      "type": "AD",
                                      "countryCode": "YQ",
                                      "currencyCode": "NGN"
                                  },
                                  "taxCategory": "X"
                              },
                              {
                                  "taxDetails": {
                                      "rate": "14289",
                                      "type": "VB",
                                      "countryCode": "YR",
                                      "currencyCode": "NGN"
                                  },
                                  "taxCategory": "X"
                              },
                              {
                                  "taxDetails": {
                                      "rate": "24536",
                                      "type": "VE",
                                      "countryCode": "NG",
                                      "currencyCode": "NGN"
                                  },
                                  "taxCategory": "X"
                              },
                              {
                                  "taxDetails": {
                                      "rate": "74407",
                                      "type": "AE",
                                      "countryCode": "QT",
                                      "currencyCode": "NGN"
                                  },
                                  "taxCategory": "X"
                              },
                              {
                                  "taxDetails": {
                                      "rate": "14882",
                                      "type": "SE",
                                      "countryCode": "TE",
                                      "currencyCode": "NGN"
                                  },
                                  "taxCategory": "X"
                              }
                          ],
                          "fareCalcRemarks": {
                              "freeText": "LOS LH FRA558.00NUC558.00END ROE1.000000",
                              "freeTextDetails": {
                                  "source": "S",
                                  "encoding": "1",
                                  "textSubjectQualifier": "FCA"
                              }
                          },
                          "issueIdentifier": {
                              "priceTariffType": "F",
                              "priceTicketDetails": {
                                  "indicators": "I"
                              }
                          },
                          "manualIndicator": {
                              "statusDetails": {
                                  "action": "N",
                                  "indicator": "M"
                              }
                          },
                          "generalIndicators": [
                              {
                                  "attributeDetails": {
                                      "attributeType": "TT",
                                      "attributeDescription": "B"
                                  }
                              },
                              {
                                  "attributeDetails": {
                                      "attributeType": "FCM",
                                      "attributeDescription": "0"
                                  }
                              }
                          ],
                          "officeInformation": {
                              "originIdentification": {
                                  "inHouseIdentification1": "LOSN828HR",
                                  "inHouseIdentification2": "WS"
                              }
                          },
                          "originDestination": {
                              "origin": "LOS",
                              "destination": "FRA"
                          },
                          "couponDetailsGroup": {
                              "productId": {
                                  "referenceDetails": {
                                      "type": "SEG",
                                      "value": "1"
                                  }
                              },
                              "fareQualifier": {
                                  "fareBasisDetails": {
                                      "rateTariffClass": "CH25",
                                      "rateTariffIndicator": "WNC",
                                      "otherRateTariffClass": "OWNG"
                                  }
                              },
                              "validityDates": [
                                  {
                                      "dateAndTimeDetails": {
                                          "date": "100923",
                                          "qualifier": "B"
                                      }
                                  },
                                  {
                                      "dateAndTimeDetails": {
                                          "date": "100923",
                                          "qualifier": "A"
                                      }
                                  }
                              ],
                              "baggageInformation": {
                                  "baggageDetails": {
                                      "quantityCode": "N",
                                      "freeAllowance": "1",
                                      "unitQualifier": "PC"
                                  }
                              }
                          }
                      }
                  },
                  {
                      "pricingRecordId": {
                          "referenceType": "TST",
                          "uniqueReference": "3"
                      },
                      "ptcDiscountCode": {
                          "discountDetails": {
                              "discountCode": "INF",
                              "unitQuantity": "1"
                          }
                      },
                      "passengerTattoos": {
                          "passengerReference": {
                              "type": "PI",
                              "value": "2"
                          }
                      },
                      "documentDetailsGroup": {
                          "flags": {
                              "statusDetails": {
                                  "action": "N",
                                  "indicator": "ISD"
                              }
                          },
                          "totalFare": {
                              "monetaryDetails": {
                                  "amount": "57814",
                                  "currency": "NGN",
                                  "typeQualifier": "T"
                              }
                          },
                          "otherDates": [
                              {
                                  "dateTime": {
                                      "day": "14",
                                      "year": "2023",
                                      "month": "7"
                                  },
                                  "businessSemantic": "PD"
                              },
                              {
                                  "dateTime": {
                                      "day": "17",
                                      "year": "2023",
                                      "month": "7"
                                  },
                                  "businessSemantic": "LT"
                              }
                          ],
                          "otherFares": [
                              {
                                  "monetaryDetails": {
                                      "amount": "74.00",
                                      "currency": "USD",
                                      "typeQualifier": "B"
                                  }
                              },
                              {
                                  "monetaryDetails": {
                                      "amount": "744.07",
                                      "typeQualifier": "D1"
                                  }
                              },
                              {
                                  "monetaryDetails": {
                                      "amount": "55061",
                                      "currency": "NGN",
                                      "typeQualifier": "E"
                                  }
                              },
                              {
                                  "monetaryDetails": {
                                      "amount": "57814",
                                      "currency": "NGN",
                                      "typeQualifier": "GT"
                                  }
                              }
                          ],
                          "creationDate": {
                              "dateTime": {
                                  "day": "14",
                                  "year": "2023",
                                  "month": "7"
                              },
                              "businessSemantic": "CRD"
                          },
                          "taxInformation": {
                              "taxDetails": {
                                  "rate": "2753",
                                  "type": "VE",
                                  "countryCode": "NG",
                                  "currencyCode": "NGN"
                              },
                              "taxCategory": "X"
                          },
                          "fareCalcRemarks": {
                              "freeText": "LOS LH FRA74.40NUC74.40END ROE1.000000",
                              "freeTextDetails": {
                                  "source": "S",
                                  "encoding": "1",
                                  "textSubjectQualifier": "FCA"
                              }
                          },
                          "issueIdentifier": {
                              "priceTariffType": "F",
                              "priceTicketDetails": {
                                  "indicators": "I"
                              }
                          },
                          "manualIndicator": {
                              "statusDetails": {
                                  "action": "N",
                                  "indicator": "M"
                              }
                          },
                          "generalIndicators": [
                              {
                                  "attributeDetails": {
                                      "attributeType": "TT",
                                      "attributeDescription": "B"
                                  }
                              },
                              {
                                  "attributeDetails": {
                                      "attributeType": "FCM",
                                      "attributeDescription": "0"
                                  }
                              }
                          ],
                          "officeInformation": {
                              "originIdentification": {
                                  "inHouseIdentification1": "LOSN828HR",
                                  "inHouseIdentification2": "WS"
                              }
                          },
                          "originDestination": {
                              "origin": "LOS",
                              "destination": "FRA"
                          },
                          "couponDetailsGroup": {
                              "productId": {
                                  "referenceDetails": {
                                      "type": "SEG",
                                      "value": "1"
                                  }
                              },
                              "fareQualifier": {
                                  "fareBasisDetails": {
                                      "rateTariffClass": "IN90",
                                      "rateTariffIndicator": "WNC",
                                      "otherRateTariffClass": "OWNG"
                                  }
                              },
                              "validityDates": [
                                  {
                                      "dateAndTimeDetails": {
                                          "date": "100923",
                                          "qualifier": "B"
                                      }
                                  },
                                  {
                                      "dateAndTimeDetails": {
                                          "date": "100923",
                                          "qualifier": "A"
                                      }
                                  }
                              ],
                              "baggageInformation": {
                                  "baggageDetails": {
                                      "quantityCode": "N",
                                      "freeAllowance": "1",
                                      "unitQualifier": "PC"
                                  }
                              }
                          }
                      }
                  }
              ]
          },
          "originDestinationDetails": {
              "itineraryInfo": {
                  "flightDetail": {
                      "productDetails": {
                          "weekDay": "7",
                          "equipment": "333",
                          "numOfStops": "0"
                      },
                      "arrivalInformation": {
                          "arrivalTerminal": "1"
                      },
                      "departureInformation": {
                          "departTerminal": "I"
                      }
                  },
                  "inventoryData": {
                      "cabinInformation": {
                          "cabinCode": "M"
                      },
                      "subClassInformation": {
                          "subclassId": "0"
                      },
                      "subClassClassification": {
                          "sourceType": {
                              "sourceQualifier1": "C"
                          },
                          "systemCode": "1A",
                          "countryCode": "NG"
                      }
                  },
                  "messageAction": {
                      "business": {
                          "function": "1"
                      }
                  },
                  "travelProduct": {
                      "product": {
                          "arrDate": "110923",
                          "arrTime": "0630",
                          "depDate": "100923",
                          "depTime": "2300",
                          "dayChangeIndicator": "2"
                      },
                      "typeDetail": {
                          "detail": [
                              "ET",
                              "I"
                          ]
                      },
                      "companyDetail": {
                          "identification": "LH"
                      },
                      "offpointDetail": {
                          "cityCode": "FRA"
                      },
                      "productDetails": {
                          "classOfService": "W",
                          "identification": "569"
                      },
                      "boardpointDetail": {
                          "cityCode": "LOS"
                      }
                  },
                  "relatedProduct": {
                      "status": "HK",
                      "quantity": "2"
                  },
                  "yieldDataGroup": {
                      "tripOnD": {
                          "origin": "LOS",
                          "destination": "FRA"
                      },
                      "ondyield": {
                          "origin": "LOS",
                          "destination": "FRA"
                      },
                      "yieldInformations": {
                          "monetaryDetails": {
                              "amount": "231",
                              "typeQualifier": "BP"
                          },
                          "otherMonetaryDetails": [
                              {
                                  "amount": "488",
                                  "typeQualifier": "OY"
                              },
                              {
                                  "amount": "0",
                                  "typeQualifier": "EY"
                              }
                          ]
                      }
                  },
                  "selectionDetails": {
                      "selection": {
                          "option": "P2"
                      }
                  },
                  "originalStatusCode": {
                      "status": [
                          "NN",
                          "NN"
                      ]
                  },
                  "pointOfSellDetails": {
                      "preferences": {
                          "userPreferences": {
                              "codedCountry": "NG"
                          }
                      },
                      "systemDetails": {
                          "deliveringSystem": {
                              "companyId": "1A",
                              "locationId": "LOS"
                          }
                      },
                      "userIdentification": {
                          "originatorTypeCode": "E",
                          "originIdentification": {
                              "originatorId": "59210421",
                              "inHouseIdentification1": "LOSN828HR"
                          }
                      }
                  },
                  "bookingCreationDate": {
                      "dateAndTimeDetails": {
                          "date": "140723",
                          "time": "1015",
                          "qualifier": "ZT"
                      }
                  },
                  "itineraryReservationInfo": {
                      "reservation": {
                          "companyId": "LH",
                          "controlNumber": "KN7IFT"
                      }
                  },
                  "elementManagementItinerary": {
                      "lineNumber": "3",
                      "segmentName": "AIR",
                      "elementReference": {
                          "number": "1",
                          "qualifier": "ST"
                      }
                  }
              },
              "originDestination": []
          }
      }
  },
  "ticketId": null,
  "createdDate": "2023-07-14T10:17:31.437Z",
  "updatedDate": "2023-07-14T10:17:31.439Z",
  "importedByUserId": "56f19426-9a9b-4e23-983e-cae4a810c7de",
  "ticketedByUserId": null,
  "importedBy": {
      "id": "56f19426-9a9b-4e23-983e-cae4a810c7de",
      "username": "admin",
      "firstName": "miles",
      "email": "admin@admin.com",
      "lastName": "admin",
      "password": "$2b$10$WhDj62jUSvpdde5MVbTUmOFGLsEvMLZreG7knbspy/fFrOHN4kHNq",
      "otp": "82820",
      "profile": "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/908.jpg",
      "isAdmin": true,
      "isSuperAdmin": true,
      "isActive": true,
      "phoneNumber": "376-910867890",
      "type": "CUSTOMER",
      "customSettingid": null,
      "gender": "MALE",
      "companyName": "PERSONAL",
      "addressLine1": "No 1 BTM Holidays street",
      "addressLine2": "No 2 TBS street",
      "postalCode": "105102",
      "cityName": "Lagos",
      "countryCode": "NG",
      "createdDate": "2023-04-18T07:04:04.447Z",
      "updatedDate": "2023-07-17T16:15:12.719Z",
      "roles": [
          "AMADEUS_BOOK",
          "AMADEUS_SEARCH",
          "AMADEUS_TICKET",
          "AMADEUS_IMPORT"
      ],
      "title": null,
      "dob": null,
      "employeeId": null,
      "employeeDepartment": null,
      "employeeDesignation": null,
      "nationalId": null,
      "nationalIdExpiry": null,
      "nationality": null
  },
  "ticketedBy": null,
  "promoCodesId": "",
  "templated": "true",
  "flightData": {
      "booked_flights": [
          {
              "airline_detail": {
                  "name": ""
              }
          }
      ]
  }
}