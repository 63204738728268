import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Dashboard from './pages/Dashboard/Index';
import Navbar from './components/Navbar';
import Flights from './pages/Flights/Index';
import FlightDetails from './pages/Flights/FlightDetails/Index';
import FlightSeats from './pages/Flights/FlightSeats/Index';
import { StyledEngineProvider, ThemeProvider, createTheme, useTheme } from '@mui/material';
import { blue, red } from '@mui/material/colors';
import { Provider, useSelector } from 'react-redux';
import { store } from './redux/store';
import Hotels from './pages/Hotels/Index';
import HotelDetails from './pages/Hotels/Details/Index';
import Transactions from './pages/Transactions/Index';
import Packages from './pages/Packages/Index';
import PackageDetails from './pages/Packages/Details/Index';
import Insurance from './pages/Insurance/Index';
import CreditLimit from './pages/CreditLimit/Index';
import CreditTransactions from './pages/CreditLimit/Transactions';
import CreditLog from './pages/CreditLimit/CreditLog';
import Customers from './pages/Passengers/Index';
import SubAgents from './pages/SubAgents/Index';
import Staff from './pages/Staff/Index';
import CreateCustomer from './pages/Passengers/Create/Index';
import EditCustomer from './pages/Passengers/Edit/Index';
import CreateSubAgent from './pages/SubAgents/Create/Index';
import CreateStaff from './pages/Staff/Create/Index';
import TransactionDetails from './pages/Transactions/Details/Index';
import OffersList from './pages/Flights/FlightSearch/OffersList';
import FlightBookProcess from './pages/Flights/book/Index';
import CustomerDetails from './pages/Passengers/Detail/Index';
import SubAgentDetails from './pages/SubAgents/Detail/Index';
import Markups from './pages/Markups/Index';
import Promotion from './pages/Settings/Promotion/Index';
import Advisory from './pages/Flights/Advisory/Index';
import Addons from './pages/Flights/Addons/Index';
import CreateAddon from './pages/Flights/Addons/Create';
import EditAddon from './pages/Flights/Addons/Edit';
import Search from './pages/Search/Index';
import SearchEmbed from './pages/SearchEmbed/Index';
import HotelsList from './pages/Hotels/HotelSearch/OffersList';
import ToursList from './pages/Tours/TourSearch/OffersList';
import HotelBookProcess from './pages/Hotels/Book/Index';
import TourBookProcess from './pages/Tours/Book/Index';
import Ticket from './pages/Flights/Ticket/Index';
import ImportPNR from "./pages/Flights/ImportPNR/Index.js";
import Terms from './components/Terms';
import Policy from './components/Policy';
import { path } from './config';
import { useEffect } from 'react';
import { hexToRgb } from './features/utils/hexToRGB';


const rootElement = document.getElementById("root");


const CustomThemeProvider = ({ children }) => {
  const {user} = useSelector((state) => state.user.userData);
  const themeHook = useTheme();

  let themeColor = user?.apiUserData?.selectedTheme || blue[700];
  if(themeColor === 'light')
    themeColor = "#00AEEF"

  let themeColorString = hexToRgb(themeColor);
  let {r,g,b} = themeColorString;
  let darker = {r: parseInt(r/4),g:parseInt(g/4),b:parseInt(b/4)}
  themeColorString = Object.values({r,g,b}).toString()
  let themeColorDark = Object.values(darker).toString()

  // alert(themeColorString+" - "+themeColorDark)

  // Apply the custom CSS rule

  useEffect(() => {
    // Update the CSS variable value
    document.documentElement.style.setProperty('--theme-color', themeColorString);
    document.documentElement.style.setProperty('--primary-color', themeColorDark);
  }, [themeColor]);


  const isColorInPalette = (color, palette) => {
    const paletteColors = Object.values(palette).reduce((acc, val) => acc.concat(Object.values(val)), []);
    console.log(paletteColors)
    return paletteColors.includes(color);
  };

  const isValidHexColor = (color) => {
    const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
    return hexColorRegex.test(color);
  };

  const isPrimaryColorSupported = isValidHexColor(themeColor) || isColorInPalette(themeColor, themeHook.palette);
  if(!isPrimaryColorSupported)
    themeColor = blue[400]

  
  // Create a new theme object with the updated primary color
  const theme = createTheme({
    palette: {
      primary: {
        main: themeColor ? themeColor : red[400],
      },
      secondary: {
        main: themeColor,
      },
      primaryLight: {
        main: themeColor ? themeColor+'aa' : "#3a3"
      }
    },
    components: {
      MuiPopover: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiPopper: {
        defaultProps: {
          container: rootElement,
        },
      },
    },
    });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

function App() {
  return (
    <Router>
      <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <CustomThemeProvider>
          <Routes>
            <Route path='search/embed/' element={<SearchEmbed />} />
            <Route path="/" element={<Navbar />}>
              <Route index element={<Dashboard />} />
              <Route path='search/' element={<Search />} />
              <Route path='search/flight/offers' element={<OffersList />} />
              <Route path='search/hotels' element={<HotelsList />} />
              <Route path='search/tours' element={<ToursList />} />

              <Route path='flights' element={<Flights />} />
              <Route path='flights/order' element={<Search  />} />
              {/* <Route path='flights/addons' element={<Addons />} /> */}
              {/* <Route path='flights/addons/create' element={<CreateAddon />} /> */}
              <Route path='flights/advisory' element={<Advisory />} />
              <Route path='flights/book/:id' element={<FlightBookProcess />} />
              <Route path='flights/details/:id' element={<FlightDetails />} />
              <Route path='flights/importPNR' element={<ImportPNR />} />
              <Route path='flights/seats/:id' element={<FlightSeats />} />
              <Route path='flights/ticket' element={<Ticket />} />

              <Route path='hotels' element={<Hotels />} />
              <Route path='hotels/order' element={<Search  />} />
              <Route path='hotels/details/:id' element={<HotelDetails />} />
              <Route path='hotels/book/:id' element={<HotelBookProcess />} />
              
              <Route path='tours/book/:id' element={<TourBookProcess />} />

              <Route path='packages' element={<Packages />} />
              <Route path='packages/details/:id' element={<PackageDetails />} />
              <Route path='packages/order' element={<Search />} />

              <Route path='insurance' element={<Insurance />} />

              <Route path='credit' element={<CreditLimit />} />
              <Route path='credit/transactions' element={<CreditTransactions />} />
              <Route path='credit/log' element={<CreditLog />} />

              <Route path='transactions' element={<Transactions />} />
              {/* <Route path='transactions/:type' element={<Transactions />} /> */}
              <Route path='transactions/details/:id' element={<TransactionDetails />} />

              <Route path='customers' element={<Customers />} />
              <Route path='customers/create' element={<CreateCustomer />} />
              <Route path='customers/edit/:id' element={<EditCustomer />} />
              <Route path='customers/detail/:id' element={<CustomerDetails />} />

              <Route path='subagents' element={<SubAgents />} />
              <Route path='subagents/create' element={<CreateSubAgent />} />
              <Route path='subagents/detail/:id' element={<SubAgentDetails />} />

              <Route path='staff' element={<Staff />} />
              <Route path='staff/create' element={<CreateStaff />} />

              <Route path='settings/markups' element={<Markups />} />
              <Route path='settings/promotion' element={<Promotion />} />
              <Route path='settings/addons' element={<Addons />} />
              <Route path='settings/addons/create' element={<CreateAddon />} />
              <Route path='settings/addons/edit/:id' element={<EditAddon />} />


              <Route path='test/embed' element={<div className='h-auto w-full'>
                <iframe src={`${path.site}/search/embed`} title='tbs-search' className='w-full h-full bg-[#f3f3f3] '></iframe>
              </div>} />
            </Route>
            <Route path="/terms" element={<Terms />} />
            <Route path="/policy" element={<Policy />} />

            <Route path="*" element={<div className='w-screen h-screen flex flex-col items-center justify-center gap-6'>
              <h1 className='text-4xl'>404 | Page Not Found</h1>
              <a href='/' className='btn2'>Go Back Home</a>
            </div>} />
          </Routes>
        </CustomThemeProvider>
      </StyledEngineProvider>
      </Provider>
    </Router>
  );
}

export default App;
